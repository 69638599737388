import React, { useEffect } from "react";
import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip as ToolTipMui,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMapContext } from "/@/context/mapContext";
import { Borough } from "/@/context/onboardingContext";
import { styled } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import dayjs, { Dayjs } from "dayjs";
import { PickersShortcutsItem } from "@mui/x-date-pickers/PickersShortcuts";
import { DateRange, SingleInputDateRangeField } from "@mui/x-date-pickers-pro";
import InteractiveMapCarousel from "/@/components/map/InteractiveMapCarousel";
import "dayjs/locale/en-gb";
import { useThemeContext } from "/@/context/themeContext";
import { useMapData } from "/@/context/useMapData";
import {
  INTERVAL_OPTION_TO_LABEL,
  METRIC_OPTION_TO_LABEL,
  METRIC_OPTIONS,
} from "/@/constants/mapConstants";
import { ChosenGuideline, Interval, Metric } from "/@/types/mapTypes";
import { filterIntervalOptions } from "/@/utils/mapUtils";

interface GraphProps {
  toggleDrawer: (open: boolean) => void;
  showInteractiveOptions?: boolean;
}

const StyledToggleButton = styled(ToggleButton)(({ theme, selected }) => ({
  width: "100%",
  "&.Mui-selected, &.Mui-selected:hover": {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.light,
  },
}));

const predefinedBoroughs = [
  { description: "Average Hackney Data", borough: "Hackney" },
  { description: "Average City of London Data", borough: "City of London" },
  { description: "Average Newham Data", borough: "Newham" },
  { description: "Average Tower Hamlets Data", borough: "Tower Hamlets" },
];

const predefinedBoroughValues = predefinedBoroughs.map(
  (borough) => borough.borough
);

const GraphSettings = ({
  toggleDrawer,
  showInteractiveOptions = true,
}: GraphProps) => {
  const { t } = useTranslation();
  const { isMobile } = useThemeContext();
  const {
    chosenDateRange,
    setChosenDateRange,
    setChosenGuideline,
    chosenGuideline,
    chosenGraphSensor,
    chosenBorough,
    setChosenGraphInterval,
    chosenGraphInterval,
    chosenGraphMetric,
    setChosenGraphMetric,
    handleBoroughChange,
    handleGraphDateRangeChange,
  } = useMapContext();

  const { getSensorReadingsByDateRange, getAverageReadingsByBorough } =
    useMapData();

  // Single useEffect to handle all parameter changes
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (chosenGraphSensor) {
          await getSensorReadingsByDateRange();
        } else if (chosenBorough) {
          await getAverageReadingsByBorough();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [
    chosenDateRange,
    chosenGraphInterval,
    chosenGraphMetric,
    chosenBorough,
    chosenGraphSensor,
    getSensorReadingsByDateRange,
    getAverageReadingsByBorough,
  ]);

  let dropdownValue = "";
  if (chosenBorough && predefinedBoroughValues.includes(chosenBorough)) {
    dropdownValue = chosenBorough;
  } else if (chosenGraphSensor) {
    dropdownValue = chosenGraphSensor.name;
  }

  const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
    {
      label: t("today"),
      getValue: () => {
        const today = dayjs();
        return [today.startOf("day"), today.endOf("day")];
      },
    },
    {
      label: t("last_7_days"),
      getValue: () => {
        const today = dayjs();
        return [today.subtract(7, "day"), today];
      },
    },
    {
      label: t("last_30_days"),
      getValue: () => {
        const today = dayjs();
        return [today.subtract(30, "day"), today];
      },
    },
    {
      label: t("last_90_days"),
      getValue: () => {
        const today = dayjs();
        return [today.subtract(90, "day"), today];
      },
    },
    {
      label: t("last_year"),
      getValue: () => {
        const today = dayjs();
        return [today.subtract(1, "year"), today];
      },
    },
  ];

  const handleGuidelineChange = (
    event: React.MouseEvent<HTMLElement>,
    newGuideline: ChosenGuideline | null
  ) => {
    event.stopPropagation(); // Prevent the click from propagating to the map
    if (newGuideline !== null) {
      setChosenGuideline(newGuideline);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <Grid container sx={{ mb: 2 }} id={"air-quality-settings"}>
        {showInteractiveOptions && (
          <Grid item xs={12} sx={{ mb: 2 }} id="tour-interactive-map-section">
            <InteractiveMapCarousel toggleDrawer={toggleDrawer} />
          </Grid>
        )}
        <Grid container spacing={1} sx={{ mb: 2 }}>
          <Grid item xs={6} id="tour-location-picker">
            <Box height={"20px"} />
            <FormControl fullWidth>
              <Autocomplete
                id="borough-select-label"
                options={predefinedBoroughValues}
                value={chosenBorough} // Use chosenBorough directly instead of a derived dropdownValue
                onChange={(event, newValue) => {
                  handleBoroughChange(newValue as Borough);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("viewing_data_for")}
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} id="tour-guidelines-switch">
            <Typography component={"p"} variant={"body2"}>
              {isMobile
                ? t("select_air_quality_guideline_mobile")
                : t("select_air_quality_guideline")}
            </Typography>
            <ToggleButtonGroup
              value={chosenGuideline}
              exclusive
              onChange={handleGuidelineChange}
              aria-label="guideline"
              fullWidth
              sx={{ height: "56px" }}
            >
              <ToolTipMui title={t("defra_toggle")} arrow enterTouchDelay={0}>
                <StyledToggleButton
                  value="DEFRA"
                  aria-label="DEFRA"
                  selected={chosenGuideline === "DEFRA"}
                  id="tour-guidelines-switch-defra"
                >
                  DEFRA
                </StyledToggleButton>
              </ToolTipMui>
              <ToolTipMui title={t("who_toggle")} arrow enterTouchDelay={0}>
                <StyledToggleButton
                  value="WHO"
                  aria-label="WHO"
                  selected={chosenGuideline === "WHO"}
                  id="tour-guidelines-switch-who"
                >
                  WHO
                </StyledToggleButton>
              </ToolTipMui>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent={"space-between"}
          id="tour-date-range-picker"
        >
          <Grid item xs={isMobile ? 12 : 6}>
            <Typography component={"p"} variant={"body2"} sx={{ mb: 1 }}>
              {t("average_period")}
            </Typography>
            {isMobile ? (
              <DateRangePicker
                calendars={1}
                name="allowedRange"
                localeText={{ start: t("start_date"), end: t("end_date") }}
                value={chosenDateRange}
                slots={{ field: SingleInputDateRangeField }}
                onChange={(newValue) => handleGraphDateRangeChange(newValue)}
                minDate={dayjs("2023-03-17")}
                maxDate={dayjs()}
                sx={{ width: "100%", pr: 0 }}
              />
            ) : (
              <DateRangePicker
                slotProps={{
                  shortcuts: {
                    items: shortcutsItems,
                  },
                }}
                slots={{ field: SingleInputDateRangeField }}
                name="allowedRange"
                localeText={{ start: t("start_date"), end: t("end_date") }}
                value={chosenDateRange}
                onChange={(newValue) => handleGraphDateRangeChange(newValue)}
                minDate={dayjs("2023-03-17")}
                maxDate={dayjs()}
                sx={{ width: "100%", pr: 1 }}
              />
            )}
          </Grid>
          {
            <>
              <Grid xs={6} md={3}>
                <FormControl fullWidth sx={{ pl: 0, mt: 3.5 }}>
                  <InputLabel id="interval-select-label">
                    {t("interval")}
                  </InputLabel>
                  <Select
                    labelId="interval-select-label"
                    id="interval-select"
                    value={chosenGraphInterval}
                    label={t("interval")}
                    onChange={(e) =>
                      setChosenGraphInterval(e.target.value as Interval)
                    }
                  >
                    {filterIntervalOptions(chosenDateRange!).map((option) => (
                      <MenuItem key={option} value={option}>
                        {INTERVAL_OPTION_TO_LABEL[option]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={6} md={3}>
                <FormControl fullWidth sx={{ pl: 1, mt: 3.5 }}>
                  <InputLabel id="metric-select-label">
                    {t("metric")}
                  </InputLabel>

                  {/*<InputLabel id="metric-select-label">{t('metric')}</InputLabel>*/}
                  {/*{chosenGraphInterval === 'hour' ? (*/}
                  {/*    <TextField*/}
                  {/*        id="metric-textfield"*/}
                  {/*        value="Average"*/}
                  {/*        label={t('metric')}*/}
                  {/*        InputProps={{*/}
                  {/*            readOnly: true,*/}
                  {/*        }}*/}
                  {/*    />*/}
                  {/*) : (*/}
                  <Select
                    labelId="metric-select-label"
                    id="metric-select"
                    value={
                      chosenGraphInterval === "hour" ? "avg" : chosenGraphMetric
                    }
                    label={t("metric")}
                    disabled={chosenGraphInterval === "hour"}
                    onChange={(e) =>
                      setChosenGraphMetric(e.target.value as Metric)
                    }
                  >
                    {METRIC_OPTIONS.map((option) => (
                      <MenuItem key={option} value={option}>
                        {METRIC_OPTION_TO_LABEL[option]}
                      </MenuItem>
                    ))}
                  </Select>
                  {/*)}*/}
                </FormControl>
              </Grid>
            </>
          }
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default GraphSettings;
